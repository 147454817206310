// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

export const updateData = createAsyncThunk(
  "api/updatereturnmaterial",
  async (payloadData) => {
    const response = await axios.post("/api/returnmaterial", payloadData.data)
    return response
  }
)

export const addData = createAsyncThunk(
  "api/addreturnmaterial",
  async (payloadData) => {
    const response = await axios.post("/api/returnmaterial", payloadData.data)
    return response
  }
)

// For Listing
export const getData = createAsyncThunk(
  "api/returnmaterial/view",
  async (params) => {
    const response = await axios.get("/api/returnmaterial/view", params)
    return {
      params,
      data: response.data,
      allData: response.data,
      totalPages: 1
    }
  }
)

// For Edit
export const fetchSingleRecord = createAsyncThunk(
  "api/returnmaterial/fetch",
  async (params) => {
    const response = await axios.get(`/api/returnmaterial/${params}`)
    return {
      data: response.data
    }
  }
)

export const appreturnmaterialSlice = createSlice({
  name: "appreturnmaterial",
  initialState: {
    allData: [
      {
        Id: 1,
        docnum: "00001",
        returndate: "19-04-2022",
        storename: "Store 1",
        returnedby: "Amy"
      },
      {
        Id: 2,
        docnum: "00002",
        returndate: "19-04-2022",
        storename: "Store 2",
        returnedby: "Bob"
      },
      {
        Id: 3,
        docnum: "00003",
        returndate: "19-04-2022",
        storename: "Store 3",
        returnedby: "Caleb"
      }
    ],
    dtoData: {},
    updated: 0
  },
  reducers: {},
  extraReducers: {
    [getData.fulfilled]: (state, { payload }) => {
      state.dtoData = {}
      state.allData = payload.allData
      state.updated = 0
    },
    [fetchSingleRecord.fulfilled]: (state, { payload }) => {
      state.dtoData = payload.data
      state.updated = 0
    },
    [addData.fulfilled]: (state) => {
      state.updated = 1
    },
    [updateData.fulfilled]: (state) => {
      state.updated = 1
    }
  }
})

export default appreturnmaterialSlice.reducer
