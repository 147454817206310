// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

export const updateData = createAsyncThunk(
  "api/updateLocation",
  async (payloadData) => {
    const response = await axios.post("/api/location", payloadData.data)
    return response
  }
)

export const addData = createAsyncThunk(
  "api/AddLocation",
  async (payloadData) => {
    const response = await axios.post("/api/location", payloadData.data)
    return response
  }
)

// shortcut addition for 3 different database
export const AddAllData = createAsyncThunk(
  "api/AddAllData",
  async (payloadData) => {
    const response = await axios.post(
      "/api/facility/AddAllData",
      payloadData.data
    )
    return response
  }
)

// For Listing
export const getData = createAsyncThunk("api/location/view", async (params) => {
  const response = await axios.get("/api/location/view", params)
  return {
    params,
    data: response.data,
    allData: response.data,
    totalPages: 1
  }
})

// For SelectablePickList
export const getLocationInBuilding = createAsyncThunk(
  "api/location/view",
  async (params) => {
    const response = await axios.get("/api/location/view", params)
    let filteredData = response.data
    if (params !== undefined && JSON.stringify(params) !== "{}") {
      filteredData = filteredData.filter((item) => {
        return Object.values(item)[2] === params
      })
      if (Object.keys(filteredData).length > 0) {
        response.data = filteredData
      }
    }
    return {
      params,
      data: response.data,
      allData: response.data,
      totalPages: 1
    }
  }
)

// For Edit
export const fetchSingleRecord = createAsyncThunk(
  "api/location/fetch",
  async (params) => {
    const response = await axios.get(`/api/location/${params}`)
    return {
      data: response.data
    }
  }
)

export const appLocationSlice = createSlice({
  name: "appLocation",
  initialState: {
    allData: [],
    dtoData: {},
    updated: 0
  },
  reducers: {},
  extraReducers: {
    [getLocationInBuilding.pending]: (state) => {
      state.dtoData = {}
      state.allData = []
      state.updated = 0
    },
    [getLocationInBuilding.fulfilled]: (state, { payload }) => {
      state.dtoData = {}
      state.allData = payload.allData
      state.updated = 0
    },
    [getData.fulfilled]: (state, { payload }) => {
      state.dtoData = {}
      state.allData = payload.allData
      state.updated = 0
    },
    [fetchSingleRecord.fulfilled]: (state, { payload }) => {
      state.dtoData = payload.data
      state.updated = 0
    },
    [addData.fulfilled]: (state) => {
      state.updated = 1
    },
    [AddAllData.fulfilled]: (state) => {
      state.updated = 1
    },
    [updateData.fulfilled]: (state) => {
      state.updated = 1
    }
  }
})

export default appLocationSlice.reducer
