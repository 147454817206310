// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

export const updateData = createAsyncThunk(
  "api/updateEquipmentType",
  async (payloadData) => {
    const response = await axios.post("/api/equipmenttype", payloadData.data)
    return response
  }
)

export const addData = createAsyncThunk(
  "api/AddEquipmentType",
  async (payloadData) => {
    const response = await axios.post("/api/equipmenttype", payloadData.data)
    return response
  }
)

// For Listing
export const getData = createAsyncThunk(
  "api/equipmenttype/view",
  async (params) => {
    const response = await axios.get("/api/equipmenttype/view", params)
    return {
      params,
      data: response.data,
      allData: response.data,
      totalPages: 1
    }
  }
)

// For Edit
export const fetchSingleRecord = createAsyncThunk(
  "api/location/fetch",
  async (params) => {
    const response = await axios.get(`/api/equipmenttype/${params}`)
    return {
      data: response.data
    }
  }
)
// For Edit
export const deleteSingleRecord = createAsyncThunk(
  "api/location/delete",
  async (params) => {
    const response = await axios.delete(`/api/equipmenttype/${params}`)
    return {
      data: response.data
    }
  }
)

export const appEquipmentTypeSlice = createSlice({
  name: "appEquipmentType",
  initialState: {
    allData: [],
    dtoData: {},
    updated: 0
  },
  reducers: {},
  extraReducers: {
    [getData.fulfilled]: (state, { payload }) => {
      state.dtoData = {}
      state.allData = payload.allData
      state.updated = 0
    },
    [fetchSingleRecord.fulfilled]: (state, { payload }) => {
      state.dtoData = payload.data
      state.updated = 0
    },
    [addData.fulfilled]: (state) => {
      state.updated = 1
    },
    [updateData.fulfilled]: (state) => {
      state.updated = 1
    },
    [deleteSingleRecord.fulfilled]: (state) => {
      state.updated = 1
    }
  }
})

export default appEquipmentTypeSlice.reducer
