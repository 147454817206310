// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

// For Listing
export const getDataName = createAsyncThunk(
  "api/reportworkorderrepeated/GetReportWorkorderRepeatedNameInfo",
  async (params) => {
    const response = await axios.get(
      `/api/reportworkorderrepeated/GetReportWorkorderRepeatedNameInfo/${params[0]}/${params[1]}`,
      params
    )
    return {
      params,
      data: response.data,
      allData: response.data,
      totalPages: 1
    }
  }
)
export const getDataType = createAsyncThunk(
  "api/reportworkorderrepeated/GetReportWorkorderRepeatedTypeInfo",
  async (params) => {
    const response = await axios.get(
      `/api/reportworkorderrepeated/GetReportWorkorderRepeatedTypeInfo/${params[0]}/${params[1]}`,
      params
    )
    return {
      params,
      data: response.data,
      allData: response.data,
      totalPages: 1
    }
  }
)
export const getDataGroup = createAsyncThunk(
  "api/reportworkorderrepeated/GetReportWorkorderRepeatedGroupInfo",
  async (params) => {
    const response = await axios.get(
      `/api/reportworkorderrepeated/GetReportWorkorderRepeatedGroupInfo/${params[0]}/${params[1]}`,
      params
    )
    return {
      params,
      data: response.data,
      allData: response.data,
      totalPages: 1
    }
  }
)

export const appWorkorderRepeatedSlice = createSlice({
  name: "appWorkorderRepeated",
  initialState: {
    allData: []
  },
  reducers: {},
  extraReducers: {
    [getDataName.fulfilled]: (state, { payload }) => {
      state.allData = payload.allData
    },
    [getDataType.fulfilled]: (state, { payload }) => {
      state.allData = payload.allData
    },
    [getDataGroup.fulfilled]: (state, { payload }) => {
      state.allData = payload.allData
    }
  }
})

export default appWorkorderRepeatedSlice.reducer
