// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

const reformatDateTime = (dateTime) => {
  const tempArray = dateTime.toString().split("T")
  const firstHalfArray = tempArray[0].split("-")
  const firstHalf = `${firstHalfArray[2]}-${firstHalfArray[1]}-${firstHalfArray[0]}`

  let secondHalf = tempArray[1].split(".")[0]
  const secondHalfArray = secondHalf.split(":")
  let hours = parseInt(secondHalfArray[0])
  if (hours >= 12) secondHalfArray.push("pm")
  else secondHalfArray.push("am")
  if (hours > 12) hours -= 12

  if (hours < 10) secondHalfArray[0] = `0${hours.toString()}`
  else secondHalfArray[0] = `${hours.toString()}`

  secondHalf = `${secondHalfArray[0]}:${secondHalfArray[1]}:${secondHalfArray[2]} ${secondHalfArray[3]}`
  const newDateTime = firstHalf.concat(" ", secondHalf)
  return newDateTime
}

const cleanUpData = (value) => {
  value["Date"] = reformatDateTime(value["Date"])
}

// For Listing
export const getData = createAsyncThunk(
  "api/servicereport/view",
  async (params) => {
    const response = await axios.get("/api/servicereport/view", params)
    response.data = response.data.filter((x) => {
      console.log(x)
      return x.Type === "CM"
    })
    response.data.forEach(cleanUpData)
    const response2 = await axios.get("/api/workorderstatus/view", params)
    response2.data = response2.data.sort((a, b) => a.value - b.value)
    const response3 = await axios.get("/api/priority/view", params)
    response3.data = response3.data.sort((a, b) => a.value - b.value)
    return {
      params,
      data: response.data,
      allData: response.data,
      statusData: { status: response2.data, priority: response3.data },
      totalPages: 1
    }
  }
)

export const appServiceReportSlice = createSlice({
  name: "appServiceReport",
  initialState: {
    allData: [],
    statusData: {}
  },
  reducers: {},
  extraReducers: {
    [getData.fulfilled]: (state, { payload }) => {
      state.allData = payload.allData
      state.statusData = payload.statusData
    }
  }
})

export default appServiceReportSlice.reducer
