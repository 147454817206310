// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

export const updateData = createAsyncThunk(
  "api/updateService",
  async (payloadData) => {
    const response = await axios.post(
      "/api/equipment/addnewequipment",
      payloadData.data
    )
    return response
  }
)

export const addData = createAsyncThunk(
  "api/AddService",
  async (payloadData) => {
    const response = await axios.post(
      "/api/equipment/addnewequipment",
      payloadData.data
    )
    return response
  }
)

// For Listing
export const getData = createAsyncThunk("api/service/view", async (params) => {
  const response = await axios.get("/api/service/view", params)
  return {
    params,
    data: response.data,
    allData: response.data,
    totalPages: 1
  }
})

// For Edit
export const fetchSingleRecord = createAsyncThunk(
  "api/service/fetch",
  async (params) => {
    const response = await axios.get(
      `/api/equipment/fetchwithlocation/${params}`
    )
    return {
      data: response.data
    }
  }
)

export const appServiceSlice = createSlice({
  name: "appService",
  initialState: {
    allData: [],
    dtoData: {},
    updated: 0
  },
  reducers: {},
  extraReducers: {
    [getData.fulfilled]: (state, { payload }) => {
      state.dtoData = {}
      state.allData = payload.allData
      state.updated = 0
    },
    [fetchSingleRecord.fulfilled]: (state, { payload }) => {
      state.dtoData = payload.data
      state.updated = 0
    },
    [addData.fulfilled]: (state) => {
      state.updated = 1
    },
    [updateData.fulfilled]: (state) => {
      state.updated = 1
    }
  }
})

export default appServiceSlice.reducer
