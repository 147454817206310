// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

// backend will reassign
export const updateData = createAsyncThunk(
  "api/AddWorkorder",
  async (payloadData) => {
    console.log(payloadData)
    const response = await axios.post(
      "/api/workorder/AddNewWO",
      payloadData.newData
    )
    return response
  }
)

export const deleteData = createAsyncThunk(
  "api/DeleteWorkorder",
  async (payloadData) => {
    const response = await axios.delete(`/api/workorder/${payloadData}`)
    return response
  }
)

export const RemoveFromFollowUp = createAsyncThunk(
  "api/RemoveFromFollowUp",
  async (payloadData) => {
    const response = await axios.get(
      `/api/workorder/RemoveFromFollowUp/${payloadData}`
    )
    return response
  }
)

export const deleteDataWithoutUpdate = createAsyncThunk(
  "api/workorder/delete",
  async (params) => {
    const response = await axios.delete(`/api/workorder/${params}`)
    return response
  }
)

// update schedule
export const updateScheduleData = createAsyncThunk(
  "api/workordertask/view",
  async (payloadData) => {
    const response = await axios.post(
      "/api/workorderscheduletask/AddAndUpdateScheduleTasks",
      payloadData.newtable
    )
    console.log(response)
    const response2 = await axios.get(
      "/api/workorderscheduletask/view",
      payloadData.Id
    )
    response2.data = response2.data.filter((x) => {
      return x.hidden_workorderID === payloadData.Id
    })
    return {
      payloadData,
      taskData: response2.data
    }
  }
)

export const RejectWorkorder = createAsyncThunk(
  "api/AddWorkorder",
  async (payloadData) => {
    const response = await axios.post(
      "/api/workorder/RejectWorkorder",
      payloadData.data
    )
    return {
      payloadData,
      data: response.data
    }
  }
)

const removeTrailingDecimals = (dateTime) => {
  return dateTime.toString().split(".")[0]
}
const reformatDateTime = (dateTime) => {
  const tempArray = dateTime.toString().split("T")
  const firstHalfArray = tempArray[0].split("-")
  const firstHalf = `${firstHalfArray[2]}-${firstHalfArray[1]}-${firstHalfArray[0]}`

  let secondHalf = tempArray[1].split(".")[0]
  const secondHalfArray = secondHalf.split(":")
  let hours = parseInt(secondHalfArray[0])
  if (hours >= 12) secondHalfArray.push("pm")
  else secondHalfArray.push("am")
  if (hours > 12) hours -= 12

  if (hours < 10) secondHalfArray[0] = `0${hours.toString()}`
  else secondHalfArray[0] = `${hours.toString()}`

  secondHalf = `${secondHalfArray[0]}:${secondHalfArray[1]}:${secondHalfArray[2]} ${secondHalfArray[3]}`
  const newDateTime = firstHalf.concat(" ", secondHalf)
  return newDateTime
}

// For Listing
export const getDataWOReset = createAsyncThunk(
  "api/workorder/getDataWOReset",
  async (params) => {
    const responseCM = []
    const responsePM = []
    const responseTBA = []
    const response = await axios.get("/api/workorder/view")
    if (response.data !== "") {
      response.data.forEach((x) => {
        if (x.Hidden_Type === "PM") responsePM.push(x)
        else if (x.Hidden_Type === "CM") responseCM.push(x)
        if (x.Hidden_TBA === true) responseTBA.push(x)
      })
    }
    const response2 = await axios.get("/api/workorderstatus/view")
    response2.data = response2.data.sort((a, b) => a.value - b.value)
    return {
      params,
      allData: response.data,
      statusData: response2.data,
      allDataPM: responsePM,
      allDataCM: responseCM,
      allDataTBA: responseTBA,
      totalPages: 1
    }
  }
)
// For Listing
export const getData = createAsyncThunk(
  "api/workorder/view",
  async (params) => {
    const responseCM = []
    const responsePM = []
    const responseTBA = []
    const response = await axios.get("/api/workorder/view")
    if (response.data !== "") {
      response.data.forEach((x) => {
        if (x.Hidden_Type === "PM") responsePM.push(x)
        else if (x.Hidden_Type === "CM") responseCM.push(x)
        if (x.Hidden_TBA === true) responseTBA.push(x)
      })
    }
    const response2 = await axios.get("/api/workorderstatus/view")
    response2.data = response2.data.sort((a, b) => a.value - b.value)
    return {
      params,
      allData: response.data,
      statusData: response2.data,
      allDataPM: responsePM,
      allDataCM: responseCM,
      allDataTBA: responseTBA,
      totalPages: 1
    }
  }
)

export const getDataByUser = createAsyncThunk(
  "api/workorder/GetWorkOrderView",
  async (params) => {
    let response = await axios.get(
      `/api/workorder/GetWorkOrderView/${params.id}`
    )
    if (response.data === "" || response.data.length < 1) {
      response = await axios.get("/api/workorder/view")
    }
    if (response.data !== "") {
      response.data = response.data.filter((x) => {
        return x.Hidden_Type === params.type
      })
    }
    response.data.forEach((x) => {
      x.Date = removeTrailingDecimals(x.Date)
    })
    const response2 = await axios.get("/api/workorderstatus/view")
    response2.data = response2.data.sort((a, b) => a.value - b.value)

    return {
      params,
      allData: response.data,
      statusData: response2.data,
      totalPages: 1
    }
  }
)
export const getDataByUserCM = createAsyncThunk(
  "api/workorder/CMGetWorkOrderView",
  async (params) => {
    let response = await axios.get(
      `/api/workorder/GetWorkOrderView/${params.id}`
    )
    if (response.data === "" || response.data.length < 1) {
      response = await axios.get("/api/workorder/view")
    }
    if (response.data !== "") {
      response.data = response.data.filter((x) => {
        return x.Hidden_Type === "CM"
      })
    }
    response.data.forEach((x) => {
      x.Date = removeTrailingDecimals(x.Date)
    })
    const response2 = await axios.get("/api/workorderstatus/view")
    response2.data = response2.data.sort((a, b) => a.value - b.value)

    return {
      params,
      allData: response.data,
      statusData: response2.data,
      totalPages: 1
    }
  }
)
export const getDataByUserPM = createAsyncThunk(
  "api/workorder/PMGetWorkOrderView",
  async (params) => {
    let response = await axios.get(
      `/api/workorder/GetWorkOrderView/${params.id}`
    )
    if (response.data === "" || response.data.length < 1) {
      response = await axios.get("/api/workorder/view")
    }
    if (response.data !== "") {
      response.data = response.data.filter((x) => {
        return x.Hidden_Type === "PM"
      })
    }
    response.data.forEach((x) => {
      x.Date = removeTrailingDecimals(x.Date)
    })
    const response2 = await axios.get("/api/workorderstatus/view")
    response2.data = response2.data.sort((a, b) => a.value - b.value)

    return {
      params,
      allData: response.data,
      statusData: response2.data,
      totalPages: 1
    }
  }
)

export const getStatusData = createAsyncThunk(
  "api/workorderstatus/view",
  async (params) => {
    const response = await axios.get("/api/workorderstatus/view", params)
    response.data = response.data.sort((a, b) => a.value - b.value)
    return {
      params,
      statusData: response.data
    }
  }
)
export const getTaskData = createAsyncThunk(
  "api/workordertask/view",
  async (params) => {
    const response = await axios.get(
      `/api/workorderscheduletask/GetLatestWorkorderSchedule/${params}`,
      params
    )
    response.data = response.data.filter((x) => {
      return x.hidden_workorderID === params
    })
    return {
      params,
      taskData: response.data
    }
  }
)

const cleanUpPastData = (value) => {
  value["Date"] = reformatDateTime(value["Date"])
  if (value["Resolved_On"]) {
    value["Resolved_On"] = reformatDateTime(value["Resolved_On"])
  }
}

// For Past Listing
export const getPastData = createAsyncThunk(
  "api/pastworkorder/view",
  async (params) => {
    const response = await axios.get("/api/pastworkorder/view", params)
    response.data.forEach(cleanUpPastData)
    return {
      pastWorkOrder: response.data
    }
  }
)

// For service request's Edit
export const fetchSingleRecord = createAsyncThunk(
  "api/workorder/fetch",
  async (params) => {
    let response
    console.log(params)
    if (params.Id) {
      response = await axios.get(`/api/workorder/fetch/${params.Id}`)
    } else {
      response = await axios.get(`/api/workorder/fetch/${params}`)
    }
    const response2 = await axios.get("/api/workorderstatus/view")
    response2.data = response2.data.sort((a, b) => a.value - b.value)
    console.log(response2)
    return {
      data: response.data,
      statusData: response2.data,
      temp: params.bool
    }
  }
)

// For downloading workorder reports
export const DownloadReport = createAsyncThunk(
  "api/workorder/DownloadWoReport",
  async (payloadData) => {
    const response = await axios
      .get(
        `/api/workorder/report/download/${payloadData.status}/${payloadData.month}`,
        {
          responseType: "blob"
        }
      )
      .then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data)

        // create "a" HTML element with href to file & click
        const link = document.createElement("a")
        link.href = href
        link.setAttribute(
          "download",
          `ScheduleReport${payloadData.month}_${payloadData.statusString}.xlsx`
        ) //or any other extension
        document.body.appendChild(link)
        link.click()
        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link)
        URL.revokeObjectURL(href)
      })
      .catch((error) => console.log(error))
    return response
  }
)
export const UploadSchedule = createAsyncThunk(
  "api/workorder/UploadSchedule",
  async (payloadData) => {
    try {
      const response = await axios.post(
        "/api/workorder/UploadSchedule",
        payloadData.data
      )
      return {
        error: response.data
      }
    } catch (e) {
      return {
        error: "Connection error: Failed to connect to API"
      }
    }
  }
)
export const downloadWorkOrderReport = createAsyncThunk(
  "api/workorder/DownloadWoReport",
  async (params) => {
    const response = await axios
      .get(`/api/workorder/downloadworeport/${params}`, {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then((response) => {
        const blobObject = new Blob([response.data])
        const responseHeaders = response.headers

        const contentDisposition = responseHeaders["content-disposition"]

        let fileName // this will get u both the file name and the extension
        if (
          contentDisposition &&
          contentDisposition.indexOf("attachment") !== -1
        ) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          const matches = filenameRegex.exec(contentDisposition)
          if (matches !== null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, "")
          }
        }

        const url = window.URL.createObjectURL(blobObject)
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", fileName) //or any other extension
        document.body.appendChild(link)
        link.click()
      })
      .catch((error) => console.log(error))
    return response
  }
)

export const appWorkorderSlice = createSlice({
  name: "appWorkorder",
  initialState: {
    allData: [],
    allDataPM: [],
    allDataCM: [],
    allDataTBA: [],
    dtoData: {},
    ttoData: {},
    ftoData: {},
    pastWorkOrder: [],
    statusData: [],
    taskData: [],
    error: "",
    updated: 0
  },
  reducers: {},
  extraReducers: {
    [getData.fulfilled]: (state, { payload }) => {
      state.dtoData = {}
      state.ftoData = {}
      state.allDataPM = payload.allDataPM
      state.allDataCM = payload.allDataCM
      state.allDataTBA = payload.allDataTBA
      state.allData = payload.allData
      state.statusData = payload.statusData
      state.taskData = state.taskData
      state.updated = 0
    },
    [getDataWOReset.fulfilled]: (state, { payload }) => {
      state.allDataPM = payload.allDataPM
      state.allDataCM = payload.allDataCM
      state.allDataTBA = payload.allDataTBA
      state.allData = payload.allData
      state.statusData = payload.statusData
      state.taskData = state.taskData
      state.updated = 0
    },
    [deleteData.fulfilled]: (state) => {
      state.updated = 1
    },
    [getDataByUser.fulfilled]: (state, { payload }) => {
      state.dtoData = {}
      state.ftoData = {}
      state.allData = payload.allData
      state.statusData = payload.statusData
      state.taskData = state.taskData
      state.updated = 0
    },
    [getDataByUserPM.fulfilled]: (state, { payload }) => {
      state.dtoData = {}
      state.ftoData = {}
      state.allData = payload.allData
      state.allDataPM = payload.allData
      state.statusData = payload.statusData
      state.taskData = state.taskData
      state.updated = 0
    },
    [getDataByUserCM.fulfilled]: (state, { payload }) => {
      state.dtoData = {}
      state.ftoData = {}
      state.allData = payload.allData
      state.allDataCM = payload.allData
      state.statusData = payload.statusData
      state.taskData = state.taskData
      state.updated = 0
    },
    [getStatusData.fulfilled]: (state, { payload }) => {
      state.ftoData = {}
      state.dtoData = state.dtoData
      state.allData = state.allData
      state.statusData = payload.statusData
      state.taskData = state.taskData
      state.updated = 0
    },
    [getTaskData.fulfilled]: (state, { payload }) => {
      state.ftoData = {}
      state.dtoData = state.dtoData
      state.allData = state.allData
      state.statusData = state.statusData
      state.taskData = payload.taskData
      state.updated = 0
    },
    [updateScheduleData.fulfilled]: (state, { payload }) => {
      state.ftoData = {}
      state.dtoData = state.dtoData
      state.allData = state.allData
      state.statusData = state.statusData
      state.taskData = payload.taskData
      state.updated = 0
    },
    [getPastData.fulfilled]: (state, { payload }) => {
      state.ftoData = {}
      state.dtoData = state.dtoData
      state.pastWorkOrder = payload.pastWorkOrder
      state.statusData = state.statusData
      state.updated = 0
    },
    [fetchSingleRecord.fulfilled]: (state, { payload }) => {
      if (payload.temp && payload.temp === true) {
        state.ftoData = {}
        state.dtoData = state.dtoData
        state.ttoData = payload.data
      } else {
        state.ftoData = {}
        state.dtoData = payload.data
        state.ttoData = state.ttoData
      }
      if (payload.statusData !== undefined)
        state.statusData = payload.statusData
      else state.statusData = state.statusData
      state.taskData = state.taskData
      state.updated = 0
    },
    [updateData.fulfilled]: (state, { payload }) => {
      state.ftoData = payload.data
      state.statusData = state.statusData
      state.taskData = state.taskData
      state.updated = 1
    },
    [UploadSchedule.fulfilled]: (state, { payload }) => {
      state.updated = payload.error !== "" ? 0 : 1
      state.error = payload.error
    }
  }
})

export default appWorkorderSlice.reducer
