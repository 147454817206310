// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

export const updateData = createAsyncThunk(
  "api/updateBuilding",
  async (payloadData) => {
    const response = await axios.post("/api/building", payloadData.data)
    return response
  }
)

export const addData = createAsyncThunk(
  "api/AddBuilding",
  async (payloadData) => {
    const response = await axios.post("/api/building", payloadData.data)
    return response
  }
)

// For Listing
export const getData = createAsyncThunk("api/building/view", async (params) => {
  const response = await axios.get("/api/building/view", params)
  return {
    params,
    data: response.data,
    allData: response.data,
    totalPages: 1
  }
})

// For Edit
export const fetchSingleRecord = createAsyncThunk(
  "api/building/fetch",
  async (params) => {
    const response = await axios.get(`/api/building/${params}`)
    return {
      data: response.data
    }
  }
)
// For Delete
export const deleteSingleRecord = createAsyncThunk(
  "api/building/delete",
  async (params) => {
    const response = await axios.delete(`/api/building/${params}`)
    return {
      data: response.data
    }
  }
)

export const appBuildingSlice = createSlice({
  name: "appBuilding",
  initialState: {
    allData: [],
    dtoData: {},
    updated: 0
  },
  reducers: {},
  extraReducers: {
    [getData.fulfilled]: (state, { payload }) => {
      state.dtoData = {}
      state.allData = payload.allData
      state.updated = 0
    },
    [fetchSingleRecord.fulfilled]: (state, { payload }) => {
      state.dtoData = payload.data
      state.updated = 0
    },
    [addData.fulfilled]: (state) => {
      state.updated = 1
    },
    [updateData.fulfilled]: (state) => {
      state.updated = 1
    },
    [deleteSingleRecord.fulfilled]: (state) => {
      state.updated = 1
    }
  }
})

export default appBuildingSlice.reducer
