// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

// For selectable picklist in redirect functionality
export const getData = createAsyncThunk(
  "api/technician/view",
  async (params) => {
    const response = await axios.get("/api/technician/view", params)
    return {
      params,
      data: response.data,
      allData: response.data,
      totalPages: 1
    }
  }
)

export const appTechnicianSlice = createSlice({
  name: "appTechnician",
  initialState: {
    allData: [],
    dtoData: {},
    updated: 0
  },
  reducers: {},
  extraReducers: {
    [getData.fulfilled]: (state, { payload }) => {
      state.dtoData = {}
      state.allData = payload.allData
      state.updated = 0
    }
  }
})

export default appTechnicianSlice.reducer
